<template>
  <div
    class="
      question-item
      w-full
      flex flex-col
      justify-start
      items-center
      h-full
      lg:flex-row
      lg:justify-center
      lg:w-8/10
      relative
      z-0
    "
  >
    <div
      class="
        h-7/10
        w-8/10
        flex flex-wrap
        m-0
        p-0
        justify-center
        items-center
        sm:h-4/10
        z-10
      "
    >
      <div class="question w-full">
        <div
          class="question w-full flex text-center text-primary text-3xl leading-8"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          {{ formDetail.description }}
        </div>
        <div
          class="w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"
          v-if="formDetail.help_info"
        >
          {{ formDetail.help_info }}
        </div>
        <div class="w-full flex flex-wrap justify-around items-center">
          <div
            v-for="(item, index) in formDetail.option_values"
            :key="item.id"
            class="bg-blur my-1 relative w-full md:w-1/3"
            data-aos="fade-left"
            data-aos-duration="500"
            :data-aos-delay="500 + index * 300"
          >
            <button
              class="
                focusForm
                flex-1
                w-full
                text-center
                cursor-pointer
                rounded-md
                border-secondary border-2
                text-md
                px-5
                py-1
                transition
                duration-300
                hover:bg-secondary
                hover:text-white
              "
              :class="[
                value && value.value == item.value
                  ? 'bg-secondary text-white'
                  : 'text-secondary',
              ]"
              @click="
                selected = item;
                canContinue = true;
                $emit('input', { id: formDetail.id, value: item.value });
                setWritingTime(500);
              "
            >
              {{ item.title }}
            </button>
          </div>
        </div>
        <transition name="fade-pop">
          <div
            v-if="canContinue == false"
            class="w-full flex justify-end items-center"
          >
            <div
              class="
                bg-red-100
                text-red-500
                border-2
                rounded-md
                border-red-400
                px-10
                py-1
                mb-5
                mt-2
              "
            >
              !Ups, necesito saber esta información para continuar, descuida, tu información está segura!
            </div>
          </div>
        </transition>
      </div>
      <div class="w-full flex justify-around items-center pb-10 sm:pb-0">
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoPrevious()"
        >
          Anterior
        </button>
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoNext()"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionOption",
  data: function () {
    return {
      canContinue: true,
      responseOptions: [],
      selected: null,
      // Para la animación de escribir
      timeout: null,
    };
  },
  components: {

  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {},
  mounted() {
    let self = this;
    self.formDetail.option_values.forEach((item) => {
      if (self.value && item.value == self.value.value) {
        self.selected = item;
      }
    });

    self.$nextTick(function () {
      document.querySelectorAll(".focusForm")[0].focus();
    });
  },
  methods: {
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous", this.question);
    },
    gotoNext() {
      let self = this;
      if (self.required) {
        if (self.selected) {
          self.$emit("next");
        } else {
          self.canContinue = false;
        }
      } else {
        self.$emit("next");
      }
    },
  },
};
</script>

<style >
.question-item {
}

.mbsc-btn-e > div {
  color: transparent !important;
}
.mbsc-sc-itm > div {
  color: transparent !important;
}

.alert {
  
}
</style>
