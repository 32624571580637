<template>
  <div
    class="
      question-item
      w-full
      flex flex-col
      justify-start
      items-center
      h-full
      lg:flex-row
      lg:justify-center
      lg:w-6/10
      relative
      z-0
    "
  >

    <div
      class="
        w-full
        h-7/10
        px-5
        flex flex-wrap
        m-0
        p-0
        justify-center
        items-center
        sm:w-7/10
        sm:h-4/10
        z-10
        relative
      "
    >
      <div class="question flex w-full flex-wrap">
        <div
          class="question w-full flex text-center text-primary text-3xl leading-8"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          {{ formDetail.description }}
        </div>
        <div
          class="w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"
          v-if="formDetail.help_info"
        >
          {{ formDetail.help_info }}
        </div>

        <!-- <h1> {{ selectedTime }} </h1> -->
        <div class="w-full flex justify-center items-center">

          <date-picker
            v-if="selectedTime"
            v-model="selectedTime"
            @input="
              $emit('input', { id: formDetail.id, value: selectedTime });
              setWritingTime(500);
            "
            :gt="formDetail.greater_than_now"
            :formClasses="'text-2xl border-2 border-primary pl-2 sm:pl-5 pr-7 py-1 sm:pr-10 text-primary rounded-md'"
          ></date-picker>
          
        </div>
        <transition name="fade-pop">
          <div v-if="canContinue == false" class="w-full flex justify-end items-center">
            <div class="bg-blue-100 text-blue-500 border-2 rounded-md border-blue-400 px-10 py-1 mb-5 mt-2">
              {{ error_message }}
            </div>
          </div>
        </transition>
      </div>


      <div class="w-full flex justify-around items-center">
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoPrevious()"
        >
          Anterior
        </button>
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoNext()"
        >
          Siguiente
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from "../date-picker.vue";
import { isMinValidAge , isMaxValidAge } from "../../constants/config";
// import { Console } from "console";

export default {
  name: "QuestionDate",
  data: function () {
    return {
      canContinue: true,
      error_message: "",
      selectedTime: null,
      timeout: null,
    };
  },
  components: {
    DatePicker,
  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {
    ...mapGetters(["loggedIn" , "userData" , "productCategories"]),
    selectedProductCategories() {    
      try {
        var response = this.productCategories.getUserSelection();
        return response
      } catch (error) {
        return []
      }
    },
  },
  mounted() {
    let self = this;
    this.selectedTime = this.value ? new Date(this.value.value) : new Date();
    // console.log("****************************");
    // console.log("self.formDetail.id: " + self.formDetail.id);
    try {
      //! Importante Revisar llenado de correo.
      if(self.formDetail.id == "2") {
        this.selectedTime = new Date(self.userData.born_date);
      }
    } catch (error) {
      // console.log(error)
      self.$emit("input", {id: self.formDetail.id , value: new Date()});
    }    
  },
  methods: {
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },

    gotoPrevious() {
      this.$emit("previous", this.question);
    },
    gotoNext() {
      let categoryIds = this.selectedProductCategories.map(item => item.id);
      let self = this;
      if (self.required) {
        if (self.selectedTime) {
          if(isMinValidAge(self.selectedTime)){
            if(isMaxValidAge(self.selectedTime , categoryIds[0])){
              self.$emit("next");
            } else {

              if(categoryIds[0] == 12){
                // Seguro de Viajeros (Max Age 85)
                self.error_message = "Lo siento debes ser menor a 85 años de edad para poder cotizar un seguro."
              } else if(categoryIds[0] == 7){
                // Seguro Gastos Médicos (Max Age 60)
                self.error_message = "Lo siento debes ser menor a 60 años de edad para poder cotizar un seguro."
              } else {
                // default response error
                self.error_message = "Lo siento debes ser menor a 70 años de edad para poder cotizar un seguro."
              }
              self.canContinue = false;
            }
          } else {
            self.error_message = "Lo siento, debes ser mayor de edad para poder cotizar un seguro."
            self.canContinue = false;
          }
        } else {
          self.error_message = "!Ups, necesito saber esta información para continuar, descuida, tu información está segura!!"
          self.canContinue = false;
        }
      } else {
        self.$emit("next");
      }
    },
  },
};
</script>




<style >

.mbsc-btn-e > div {
  color: transparent !important;
}
.mbsc-sc-itm > div {
  color: transparent !important;
}
.mbsc-mobiscroll .mbsc-fr-w {
  background: transparent;
  color: #9ac452;
}
.rotateY-180 {
  transform: rotateY(180deg);
}
</style>
