<template>
  <div
    class="
      question-item
      w-full
      flex flex-col
      justify-start
      items-center
      h-full
      lg:flex-row
      lg:justify-center
      lg:w-8/10
      relative
      z-0
    "
  >

    <div
      class="
        h-7/10
        w-8/10
        flex flex-wrap
        m-0
        p-0
        justify-center
        items-center
        sm:h-4/10
        z-10
      "
    >
      <div class="question">
        <div
          class="question w-full flex text-center text-primary text-3xl leading-8"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          <!-- {{ formDetail }} -->
          <!-- <br><br>
          {{ formDetail.description }} -->
          {{ formDetail.description }}
        </div>
        <div
          class="w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"
          v-if="formDetail.help_info"
        >
          {{ formDetail.help_info }}
        </div>











        <div class="w-full flex flex-wrap justify-center items-center">

          <!-- This is only used by Sura Gastos Médicos -->
          <v-select
            v-if="formDetail.id == 38"
            ref="focusForm"
            v-model="selectedValue"
            :options="[{'name':'Hombre', 'value':'H'} , {'name':'Mujer', 'value':'M'}]"
            @input="
              $emit('input', { id: formDetail.id, value: selectedValue });
              canContinue = true;
              setWritingTime(500);
            "
            index="id"
            return-object
            label="name"
            class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
            style="width:100%;"
          >
            <template slot="no-options"> Escribe para buscar</template>

          </v-select>




          <!--? Sura Código Postal -->
          <div v-else-if="formDetail.id == 39" style="width:30rem;">

            <p>Código Postal <span v-if="selectedValue">({{ selectedValue.zipcode }})</span></p>

            <v-select
              ref="focusForm"
              v-model="selectedValue"
              :options="optionList"
              @input="
                $emit('input', { id: formDetail.id, value: selectedValue });
                canContinue = true;
                setWritingTime(500);
              "
              @search="onSearchZipCodeSura"
              index="id"
              return-object
              label="name"
              class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
              style="width:100%; margin-bottom:2rem;"
            >
              <template slot="no-options"> Escribe para buscar </template>
              <!-- <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template> -->
            </v-select>

            <!-- {{ selectedValue }} -->

            <div v-if="
              zipcodeSura.state.length != 0 && 
              zipcodeSura.municipality.length != 0 &&
              zipcodeSura.city.length != 0 &&
              zipcodeSura.colony.length != 0
            ">

              <p>Estado (Campo Requerido)</p>
              <v-select
                ref="focusForm"
                v-model="selectedValue.state"
                :options="zipcodeSura.state"
                @input="
                  $emit('input', { id: formDetail.id, value: selectedValue });
                  canContinue = true;
                  setWritingTime(500);
                "
                index="id"
                return-object
                label="name"
                class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
                style="width:100%;"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
              </v-select>

              <p>Municipio (Campo Requerido)</p>
              <v-select
                ref="focusForm"
                v-model="selectedValue.municipality"
                :options="zipcodeSura.municipality"
                @input="
                  $emit('input', { id: formDetail.id, value: selectedValue });
                  canContinue = true;
                  setWritingTime(500);
                "
                index="id"
                return-object
                label="name"
                class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
                style="width:100%;"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
              </v-select>


              <p>Ciudad (Campo Requerido)</p>
              <v-select
                ref="focusForm"
                v-model="selectedValue.city"
                :options="zipcodeSura.city"
                @input="
                  $emit('input', { id: formDetail.id, value: selectedValue });
                  canContinue = true;
                  setWritingTime(500);
                "
                index="id"
                return-object
                label="name"
                class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
                style="width:100%;"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
              </v-select>


              <p>Colonia (Campo Requerido)</p>
              <v-select
                ref="focusForm"
                v-model="selectedValue.colony"
                :options="zipcodeSura.colony"
                @input="
                  $emit('input', { id: formDetail.id, value: selectedValue });
                  canContinue = true;
                  setWritingTime(500);
                "
                index="id"
                return-object
                label="name"
                class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
                style="width:100%; margin-bottom:8rem;"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descripcion }}
                  </div>
                </template>
              </v-select>

            </div>
            
          </div>















          <v-select
            v-else
            ref="focusForm"
            v-model="selectedValue"
            :options="optionList"
            @search="onSearch"
            @input="
              $emit('input', { id: formDetail.id, value: selectedValue });
              canContinue = true;
              setWritingTime(500);
            "
            index="id"
            return-object
            label="name"
            class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"
            style="width:100%;"
          >
            <template slot="no-options"> Escribe para buscar </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>

        </div>
        <transition name="fade-pop">
          <div v-if="!canContinue" class="w-full flex justify-end items-center">
            <div
              class="
                bg-red-100
                text-red-500
                border-2
                rounded-md
                border-red-400
                px-10
                py-1
              "
            >
              !Ups, necesito saber esta información para continuar, descuida, tu información está segura!
            </div>
          </div>
        </transition>
      </div>
      <div class="w-full flex justify-around items-center pb-10 sm:pb-0">
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoPrevious()"
        >
          Anterior
        </button>
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoNext()"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import _ from "lodash";
export default {
  name: "QuestionOption",
  data:() => ({
    selectedValue: null,
    
    zipcodeSura: {
      zipcode:"",
      state:[],
      colony:[],
      municipality:[],
      city:[]
    },

    canContinue: true,
    responseOptions: [],
    selected: null,
    // Para la animación de escribir
    timeout: null,
    optionList: [],
  }),
  components: {
  
  },
  props: {
    api_resource: {
      required: true,
    },
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
    httpConnector: {},
  },
  computed: {},
  mounted() {
    let self = this;
    self.selectedValue = self.value ? self.value.value : null;
  },
  methods: {
    setOptions(data) {
      this.optionList = data;
    },
    onSearch(search, loading) {

      // console.log("buscando", search);
      if (search.length) {
        loading(true);
        this.search(
          loading,
          search,
          this,
          this.api_resource,
          this.httpConnector
        );
      }
    },

    search: _.debounce((loading, search, vm, api_resource, httpConnector) => {
      
      let url = api_resource + "?value=" + String(search);
      let http = httpConnector ? httpConnector : axios;
    
      http.get(url).then((res) => {
        vm.setOptions(res.data);
        loading(false);
      });

    }, 350),

    onSearchZipCodeSura(search, loading) {

      // console.log("buscando", search);
      if (search.length) {
        this.search_sura(
          loading,
          search,
          this,
          this.api_resource,
          this.httpConnector
        );
      }
    },
    search_sura: _.debounce((loading, search, vm, api_resource, httpConnector) => {

      var self = this
      let url = api_resource + "?value=" + String(search);
      let http = httpConnector ? httpConnector : axios;

      try {
        
        var zip_code = parseInt(search)
        
        // We validate the size of 5 digits of the zipcode
        if(search.length == 5 && Number.isInteger(zip_code)){

          loading(true)
          http.get(url).then(({data}) => {

            var { state , colony , municipality , city } = data.results
            var { success , message } = city
            
            if(success != false){

              // eslint-disable-next-line
              var { success , errors } = data.quotation.response_cotization

              if(success != false){
                
                vm.selectedValue = { zipcode:search }
                vm.zipcodeSura = { state , colony , municipality , city }

              } else {

                var { description } = errors[0]

                vm.$swal({
                  icon: "error",
                  title: 'Oops!',
                  text: description,
                  showConfirmButton: false,
                  timer:4000
                })

              }
              
            } else {
              
              vm.$swal({
                icon: "error",
                title: 'Oops!',
                text: message,
                showConfirmButton: false,
                timer:4000
              })

              vm.zipcodeSura = {
                zipcode:"",
                state:[],
                colony:[],
                municipality:[],
                city:[]
              }

            }

            loading(false)

          })

        }

      } catch (error) {

        vm.$swal({
          icon: "info",
          title: 'Oops!',
          text: "Unicamente puedes ingresar valores númericos",
          showConfirmButton: false,
          timer:4000
        })

      }

    }, 350),

    
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous", this.question);
    },
    gotoNext() {
      let self = this;
      if (self.required) {
        
        if (self.selectedValue) {
          

          // Validation Specific for Sura Seguros
          if(self.formDetail.id == 39){

            var selectedValue = self.selectedValue
            if(
              selectedValue.state &&
              selectedValue.colony &&
              selectedValue.municipality &&
              selectedValue.city
            ){
              self.$emit("next");
            } else {
              self.canContinue = false;
            }

          } else {
            self.$emit("next");
          }
          
        } else {
          self.canContinue = false;
        }

      } else {
        self.$emit("next");
      }
    },
  },
};
</script>



<style >
</style>