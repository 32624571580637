<template>
  <div class="date-picker flex">
    <div 
      class="custom-select"
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-delay="300"
    >
      <!-- <h1> {{ value }} </h1> -->
      <!--
        <h1> {{ startingYear }} </h1>
        <h1> {{ selectedYear }} </h1>
        <h1> {{ selectedMonth }} </h1>
        <h1> {{ selectedDay }} </h1> 
      -->

      <select
        ref="focusForm"
        v-model="selectedYear"
        @change="updateSelectedDate()"
        class="border border-primary pl-2 sm:pl-5 pr-7 sm:pr-10 pb-2 pt-2 text-primary rounded-md relative"
        :class="[formClasses]"
      >
        <option
          v-for="i in yearDistance"
          :key="i"
          :value="!gt ? startingYear - i + 1: startingYear + i -1"
        >
          {{ !gt ? startingYear - i + 1: startingYear + i - 1}}
        </option>
      </select>
    </div>
    <div
      class="custom-select"
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-delay="600"
    >
      <select
        v-model="selectedMonth"
        @change="updateSelectedDate()"
        class="border border-primary pl-2 sm:pl-5 pr-7 sm:pr-10 pb-2 pt-2 text-primary rounded-md relative"
        :class="[formClasses]"
      >
        <option v-for="(month, index) in months" :key="month" :value="index" :disabled="selectableMonths.includes(index)">
          {{ month }}
        </option>
      </select>
    </div>
    <div
      class="custom-select"
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-delay="900"
    >
      <select
        v-model="selectedDay"
        @change="updateSelectedDate()"
        class="border border-primary pl-2 sm:pl-5 pr-7 sm:pr-10 pb-2 pt-2 text-primary rounded-md relative"
        :class="[formClasses]"
      >
        <option v-for="(i) in numbrerOfDays" :key="i" :value="i" :disabled="selectableDays.includes(i)">
          {{ i }}
        </option>
      </select>
    </div>
  </div>
</template>



<script>

import { mapGetters } from "vuex";

export default {
  name: "slideOptions",
  data: function() {
    return {
      months: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre"
      ],
      startingYear: new Date().getFullYear() - 18,
      selectedYear: new Date().getFullYear() - 18,
      selectedMonth: new Date().getMonth(),
      selectedDay: new Date().getDate(),
    };
  },
  props: {
    value: {},
    yearDistance: {
      type: Number,
      default: 100
    },
    gt: {
      type: Boolean,
      default: false
    },
    gtDate: {
      type: Date,
      default: null
    },
    formClasses: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["userData"]),
    numbrerOfDays() {
      return this.getDaysInMonth(this.selectedYear, this.selectedMonth + 1);
    },
    selectableMonths() {
      // console.log("SelectableMonths")
      // console.log(this.value.getFullYear())
      // // this.selectedMonth = this.value.getMonth();
      // console.log(this.value.getMonth())
      // // this.selectedDay = this.value.getDate()+1;
      // console.log(this.value.getDate())

      let invalidMonths = [];
      if (this.gt && this.selectedYear == new Date().getFullYear()) {
        invalidMonths = [...this.range(0, new Date().getMonth() - 1)]
      }
      if (!this.gt && this.selectedYear == new Date().getFullYear()) {
        invalidMonths = [...this.range(new Date().getMonth() + 1, 12)]
      }
      return invalidMonths
    },
    selectableDays() {
      let invalidDays = [];
      if (this.gt && this.selectedYear == new Date().getFullYear() && this.selectedMonth == new Date().getMonth()) {
        invalidDays = [...this.range(0, new Date().getDate() - 1)]
      }
      if (!this.gt && this.selectedYear == new Date().getFullYear() && this.selectedMonth == new Date().getMonth()) {
        invalidDays = [...this.range(new Date().getDate() + 1, 31)]
      }
      return invalidDays
    }
  },

  mounted() {
    // console.log("mounted")

    let self = this;
    self.$nextTick(function () {
      self.$refs["focusForm"].focus();
    });

    // console.log(this.value)
    // console.log("UserDataBefore")
    // console.log(this.userData.born_date)
    var user_borndate = this.userData.born_date
    var user_borndate_year = user_borndate.substring(0,4)
    var user_borndate_month = user_borndate.substring(5,7)
    var user_borndate_day = user_borndate.substring(8,10)

    if (self.value) {

      self.selectedYear = parseInt(user_borndate_year);
      // console.log(self.selectedYear)
      self.selectedMonth = parseInt(user_borndate_month)-1;
      // console.log(self.selectedMonth)
      self.selectedDay = parseInt(user_borndate_day);
      // console.log(self.selectedDay)

      self.$emit(
        "input",
        new Date(self.selectedYear, self.selectedMonth, self.selectedDay)
      )
    } else {
      self.$emit(
        "input",
        new Date(self.selectedYear, self.selectedMonth, self.selectedDay)
      );
    }
  },
  methods: {
    * range(start, end) {
        for (let i = start; i <= end; i++) {
            yield i;
        }
    },
    getDaysInMonth(year, month) {
      // console.log(year, month);
      return new Date(year, month, 0).getDate();
    },
    updateSelectedDate() {
      this.$emit(
        "input",
        new Date(this.selectedYear, this.selectedMonth, this.selectedDay)
      );
    }
  }
};
</script>





<style scoped >
/* wrapper-div for including arrow */
.custom-select {
  background-color: transparent;
  float: left;
  margin-right: 10px;
  position: relative;
}
.custom-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; /* remove default styling */
  background-color: inherit;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

/* drop arrow */
.custom-select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(124, 94, 206)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: calc(50%);
  background-size: 40px 40px;
}

option {
  text-transform: none;
}

</style>
