var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    question-item\n    w-full\n    flex flex-col\n    justify-start\n    items-center\n    h-full\n    lg:flex-row\n    lg:justify-center\n    lg:w-8/10\n    relative\n    z-0\n  "},[_c('div',{staticClass:"\n      h-7/10\n      w-8/10\n      flex flex-wrap\n      m-0\n      p-0\n      justify-center\n      items-center\n      sm:h-4/10\n      z-10\n    "},[_c('div',{staticClass:"question"},[_c('div',{staticClass:"question w-full flex text-center text-primary text-3xl leading-8",attrs:{"data-aos":"fade-left","data-aos-duration":"500"}},[_vm._v("\n        "+_vm._s(_vm.formDetail.description)+"\n      ")]),_vm._v(" "),(_vm.formDetail.help_info)?_c('div',{staticClass:"w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"},[_vm._v("\n        "+_vm._s(_vm.formDetail.help_info)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-wrap justify-center items-center"},[(_vm.formDetail.id == 38)?_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%"},attrs:{"options":[{'name':'Hombre', 'value':'H'} , {'name':'Mujer', 'value':'M'}],"index":"id","return-object":"","label":"name"},on:{"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
            _vm.canContinue = true;
            _vm.setWritingTime(500);}},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},[_c('template',{slot:"no-options"},[_vm._v(" Escribe para buscar")])],2):(_vm.formDetail.id == 39)?_c('div',{staticStyle:{"width":"30rem"}},[_c('p',[_vm._v("Código Postal "),(_vm.selectedValue)?_c('span',[_vm._v("("+_vm._s(_vm.selectedValue.zipcode)+")")]):_vm._e()]),_vm._v(" "),_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%","margin-bottom":"2rem"},attrs:{"options":_vm.optionList,"index":"id","return-object":"","label":"name"},on:{"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
              _vm.canContinue = true;
              _vm.setWritingTime(500);},"search":_vm.onSearchZipCodeSura},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},[_c('template',{slot:"no-options"},[_vm._v(" Escribe para buscar ")])],2),_vm._v(" "),(
            _vm.zipcodeSura.state.length != 0 && 
            _vm.zipcodeSura.municipality.length != 0 &&
            _vm.zipcodeSura.city.length != 0 &&
            _vm.zipcodeSura.colony.length != 0
          )?_c('div',[_c('p',[_vm._v("Estado (Campo Requerido)")]),_vm._v(" "),_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%"},attrs:{"options":_vm.zipcodeSura.state,"index":"id","return-object":"","label":"name"},on:{"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
                _vm.canContinue = true;
                _vm.setWritingTime(500);}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}}],null,false,3163572772),model:{value:(_vm.selectedValue.state),callback:function ($$v) {_vm.$set(_vm.selectedValue, "state", $$v)},expression:"selectedValue.state"}}),_vm._v(" "),_c('p',[_vm._v("Municipio (Campo Requerido)")]),_vm._v(" "),_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%"},attrs:{"options":_vm.zipcodeSura.municipality,"index":"id","return-object":"","label":"name"},on:{"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
                _vm.canContinue = true;
                _vm.setWritingTime(500);}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}}],null,false,3163572772),model:{value:(_vm.selectedValue.municipality),callback:function ($$v) {_vm.$set(_vm.selectedValue, "municipality", $$v)},expression:"selectedValue.municipality"}}),_vm._v(" "),_c('p',[_vm._v("Ciudad (Campo Requerido)")]),_vm._v(" "),_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%"},attrs:{"options":_vm.zipcodeSura.city,"index":"id","return-object":"","label":"name"},on:{"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
                _vm.canContinue = true;
                _vm.setWritingTime(500);}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}}],null,false,3163572772),model:{value:(_vm.selectedValue.city),callback:function ($$v) {_vm.$set(_vm.selectedValue, "city", $$v)},expression:"selectedValue.city"}}),_vm._v(" "),_c('p',[_vm._v("Colonia (Campo Requerido)")]),_vm._v(" "),_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%","margin-bottom":"8rem"},attrs:{"options":_vm.zipcodeSura.colony,"index":"id","return-object":"","label":"name"},on:{"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
                _vm.canContinue = true;
                _vm.setWritingTime(500);}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.descripcion)+"\n                ")])]}}],null,false,3163572772),model:{value:(_vm.selectedValue.colony),callback:function ($$v) {_vm.$set(_vm.selectedValue, "colony", $$v)},expression:"selectedValue.colony"}})],1):_vm._e()],1):_c('v-select',{ref:"focusForm",staticClass:"w-full text-black t-form rounded-md border-2 text-xl md:text-2xl",staticStyle:{"width":"100%"},attrs:{"options":_vm.optionList,"index":"id","return-object":"","label":"name"},on:{"search":_vm.onSearch,"input":function($event){_vm.$emit('input', { id: _vm.formDetail.id, value: _vm.selectedValue });
            _vm.canContinue = true;
            _vm.setWritingTime(500);}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v("\n              "+_vm._s(option.name)+"\n            ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n              "+_vm._s(option.name)+"\n            ")])]}}]),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},[_c('template',{slot:"no-options"},[_vm._v(" Escribe para buscar ")])],2)],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade-pop"}},[(!_vm.canContinue)?_c('div',{staticClass:"w-full flex justify-end items-center"},[_c('div',{staticClass:"\n              bg-red-100\n              text-red-500\n              border-2\n              rounded-md\n              border-red-400\n              px-10\n              py-1\n            "},[_vm._v("\n            !Ups, necesito saber esta información para continuar, descuida, tu información está segura!\n          ")])]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"w-full flex justify-around items-center pb-10 sm:pb-0"},[_c('button',{staticClass:"\n          py-2\n          px-5\n          border-2 border-primary\n          rounded-md\n          text-primary\n          hover:bg-primary\n          hover:text-white\n          transition\n          duration-600\n          pop\n        ",on:{"click":function($event){return _vm.gotoPrevious()}}},[_vm._v("\n        Anterior\n      ")]),_vm._v(" "),_c('button',{staticClass:"\n          py-2\n          px-5\n          border-2 border-primary\n          rounded-md\n          text-primary\n          hover:bg-primary\n          hover:text-white\n          transition\n          duration-600\n          pop\n        ",on:{"click":function($event){return _vm.gotoNext()}}},[_vm._v("\n        Siguiente\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }